import React, { useState, useEffect } from "react";
import "./productInfo.scss";
import urls from "../../../../utils/services/apiUrl.json";
import { getMethodWithPlatfrom, postMethodWithToken } from "../../../../utils/services/api";
import { getUserRole } from "../../../../utils/common";
import Pagination from '@mui/material/Pagination';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import moment from "moment";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


function ProductInfo({ orderId, storeId, orderProductInfoData, customerDetails, refundValue, orderStatus }) {
    const itemsPerPage = 20;
    const OrderIdParam = useParams()
    const [orderProductInfo, setOrderProductInfo] = useState([]);
    const [orderCancleProductInfo, setOrderCancleProductInfo] = useState([]);
    const [orderReturnProductInfo, setOrderReturnProductInfo] = useState([]);
    const [refundAmount, setRefundAmount] = useState("");
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (orderProductInfoData?.count !== 0 && orderProductInfoData?.count !== undefined) {
            setCount(Math.ceil(orderProductInfoData?.count / itemsPerPage))
        }

        if (orderProductInfoData?.results?.data && orderProductInfoData?.results?.data?.length > 0) {
            setOrderProductInfo(orderProductInfoData?.results?.data);
        }

        if (orderProductInfoData?.results?.cancellation && orderProductInfoData?.results?.cancellation?.length > 0) {
            setOrderCancleProductInfo(orderProductInfoData?.results?.cancellation);
        }

        if (orderProductInfoData?.results?.returned && orderProductInfoData?.results?.returned?.length > 0) {
            setOrderReturnProductInfo(orderProductInfoData?.results?.returned);
        }

        if (orderProductInfoData?.results?.RefundAmount) {
            setRefundAmount(orderProductInfoData?.results?.RefundAmount);
        }
    }, []);

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)

        if (getUserRole() !== "super-admin") {
            await getMethodWithPlatfrom(urls.ordersMerchant + "?order_id=" + orderId + "&page=" + value, 'merchant').then((response) => {
                if (response.status === 200) {
                    setCount(Math.ceil(response?.data?.count / itemsPerPage))
                    setOrderProductInfo(response?.data?.results?.data);
                    setLoading(false);
                }
            });

        } else {
            await getMethodWithPlatfrom(urls.ordersAdmin + "?stores_id=" + storeId + "&order_id=" + orderId + "&page=" + value, 'super-admin').then((response) => {
                if (response.status === 200) {
                    setCount(Math.ceil(response?.data?.count / itemsPerPage))
                    setOrderProductInfo(response?.data?.results?.data);
                    setLoading(false);
                }
            });
        }

        setLoading(false);
    }

    const changeToFloat = (value) => {
        let valueInFloat = value !== null ? value.toFixed(2) : "";
        return valueInFloat;
    };
    const timeFormat = (date) => {
        let requiredFormat = moment(date).format("lll");
        return requiredFormat;
    };

    return (
        <>
            <div className="row" style={{ marginLeft: "32px" }}>
                <div className="col-12 p-0" style={{ overflowX: "hidden" }}>
                    <div className="d-flex p-0 mt-4">
                        {OrderIdParam.order === "pickUp" ?
                            <div className="h4-label" style={{ display: "flex", alignItems: "center", paddingLeft: "14px" }}>
                                Pick Up Order {customerDetails && customerDetails?.number}
                            </div>
                            :
                            <div className="h4-label" style={{ display: "flex", alignItems: "center", paddingLeft: "12px" }}>
                                Ship to Address Order {customerDetails && customerDetails?.number}
                            </div>
                        }
                        <Box style={{
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            maxWidth: '158px',
                            height: "32px",
                            marginLeft: '20px',
                            display: 'flex',
                            alignItems: "center"
                        }}
                            bgcolor="#00B050" color="white" textAlign="center" borderRadius="20px">
                            {customerDetails?.paymentConfirmation}
                        </Box>
                        {(OrderIdParam?.express && (orderStatus === "Order Received" || orderStatus === "Order in Process" || orderStatus === "Order Ready")) &&
                            <Box style={{ paddingLeft: '15px', paddingRight: '15px', maxWidth: '148px', height: "32px", marginLeft: '15px', display: 'flex', alignItems: "center" }} bgcolor="#FF0000" color="white" textAlign="center" borderRadius="20px">
                                Rush Order
                            </Box>
                        }
                        {(OrderIdParam?.express && orderStatus === "Order Delivered") &&
                            <Box style={{ paddingLeft: '15px', paddingRight: '15px', maxWidth: '148px', height: "32px", marginLeft: '15px', display: 'flex', alignItems: "center" }} bgcolor="#1D3762" color="white" textAlign="center" borderRadius="20px">
                                Order Delivered
                            </Box>
                        }

                    </div>
                    <div className="col-md-12 col-lg-12">
                        <div className="shiptext2 mt-3">
                            Received on{" "}
                            {customerDetails && customerDetails?.date_placed ? timeFormat(customerDetails.date_placed) : ""}
                            {" "}
                            from{" "}
                            {customerDetails && customerDetails?.first_name + " " + customerDetails?.last_name}
                        </div>
                    </div>

                    {count > 1 &&
                        <div className="row pl-2 pr-2 mt-4">
                            <div className="col-12">
                                <div className="shopPagination">
                                    <Pagination
                                        count={count}
                                        page={page}
                                        showFirstButton
                                        showLastButton
                                        onChange={(event, value) => handlePageChange(event, value)}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {orderProductInfo?.length > 0 &&
                        <div className="row ml-0 mr-4 mt-5 setting_product_name">
                            <div className="col-1 productItem f-600">S.No</div>
                            <div className="col-3 productItem f-600">Product Name</div>
                            <div className="col-1 productItem f-600">UPC</div>
                            <div className="col-1 productItem f-600">SKU</div>
                            <div className="col-1 productItem f-600">Qty.</div>
                            <div className="col-2 productItem f-600">Cost</div>
                            <div className="col-2 productItem f-600">Price</div>
                            <div className="col-1 productItem f-600">Discount</div>
                        </div>
                    }

                    {orderProductInfo.length > 0 && orderProductInfo.map((item, index) => {
                        return (
                            <div className="row mt-3 pt-3 pb-3 mb-4 setting_product_name" style={{ background: "#ECECEC", marginRight: "30px" }}>
                                <div className="col-1 d-flex justify-content-center" style={{ alignItems: "center" }}>
                                    {index + 1}.
                                </div>
                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    <div className="d-flex">
                                        <img className="listedImg" src={item?.product_images?.[0]?.original} alt={item?.product_name} />
                                    </div>
                                    <div className="ml-2" style={{ alignItems: "center" }}>
                                        <div>{item?.product_name}<br /></div>
                                        <div className="pDetail"><b>sku</b> : {item?.sku}</div>
                                    </div>
                                </div>
                                <div className="col-1" style={{ paddingTop: "10px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                    {item?.upc}
                                </div>
                                <div className="col-1" style={{ paddingTop: "10px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                    {item?.sku}
                                </div>
                                <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                    {item?.quantity}
                                </div>
                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                    ${changeToFloat(+ item?.cost)}
                                </div>
                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                    ${changeToFloat(+ item?.price)}
                                </div>
                                <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                    {item?.discount}
                                </div>
                            </div>
                        );
                    })}

                    {count > 1 &&
                        <div className="row pl-2 pr-2 mt-4">
                            <div className="col-12">
                                <div className="shopPagination">
                                    <Pagination
                                        count={count}
                                        page={page}
                                        showFirstButton
                                        showLastButton
                                        onChange={(event, value) => handlePageChange(event, value)}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {/*<p className="">
                <span style={{ color: "orange" }}>Note:</span> This is a gift.
            </p>*/}

                    {orderReturnProductInfo.length > 0 &&
                        <>
                            <div className="row pl-2 pr-2 mt-4">
                                <div className="col-12">
                                    <h4 className="h4-label">Returned Item(s)</h4>
                                </div>
                                <div className="row ml-0 mt-4 setting_product_name" style={{ marginRight: "30px" }}>
                                    <div className="col-1 productItem f-600">S.No</div>
                                    <div className="col-3 productItem f-600">Product Name</div>
                                    <div className="col-1 productItem f-600">UPC</div>
                                    <div className="col-1 productItem f-600">SKU</div>
                                    <div className="col-1 productItem f-600">Qty.</div>
                                    <div className="col-2 productItem f-600">Cost</div>
                                    <div className="col-2 productItem f-600">Price</div>
                                    <div className="col-1 productItem f-600">Discount</div>
                                </div>
                            </div>

                            {orderReturnProductInfo.length > 0 && orderReturnProductInfo.map((item, index) => {
                                return (
                                    <div className="row mt-3 pt-3 pb-3 mb-4 setting_product_name" style={{ background: "#ECECEC", marginRight: "30px" }}>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            {index + 1}.
                                        </div>
                                        <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                            <div className="d-flex">
                                                <img className="listedImg" src={item?.product_images?.[0]?.original} alt={item?.product_name} />
                                            </div>
                                            <div className="ml-2" style={{ alignItems: "center" }}>
                                                <div>{item?.product_name}<br /></div>
                                                <div className="pDetail"><b>sku</b> : {item?.sku}</div>
                                            </div>
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            {item?.upc}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            {item?.sku}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            {item?.quantity}
                                        </div>
                                        <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                            ${changeToFloat(+ item?.cost)}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            ${changeToFloat(+ item?.price)}
                                        </div>
                                        <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                            {item?.discount}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    }

                    {orderCancleProductInfo.length > 0 &&
                        <>
                            <div className="row pl-2 pr-2 mt-4">
                                <div className="col-12">
                                    <h4 className="h4-label">Cancled Item(s)</h4>
                                </div>
                                <div className="row ml-0 mt-4 setting_product_name" style={{ marginRight: "30px" }}>
                                    <div className="col-1 productItem f-600">S.No</div>
                                    <div className="col-3 productItem f-600">Product Name</div>
                                    <div className="col-1 productItem f-600">UPC</div>
                                    <div className="col-1 productItem f-600">SKU</div>
                                    <div className="col-1 productItem f-600">Qty.</div>
                                    <div className="col-2 productItem f-600">Cost</div>
                                    <div className="col-2 productItem f-600">Price</div>
                                    <div className="col-1 productItem f-600">Discount</div>
                                </div>
                            </div>

                            {orderCancleProductInfo.length > 0 && orderCancleProductInfo.map((item, index) => {
                                return (
                                    <div className="row mt-3 pt-3 pb-3 mb-4 setting_product_name" style={{ background: "#ECECEC", marginRight: "30px" }}>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            {index + 1}.
                                        </div>
                                        <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                            <div className="d-flex">
                                                <img className="listedImg" src={item?.product_images?.[0]?.original} alt={item?.product_name} />
                                            </div>
                                            <div className="ml-2" style={{ alignItems: "center" }}>
                                                <div>{item?.product_name}<br /></div>
                                                <div className="pDetail"><b>sku</b> : {item?.sku}</div>
                                            </div>
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            {item?.upc}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            {item?.sku}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            {item?.quantity}
                                        </div>
                                        <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                            ${changeToFloat(+ item?.cost)}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            ${changeToFloat(+ item.price)}
                                        </div>
                                        <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                            {item?.discount}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    }
                    {/* {(getUserRole() === "super-admin" ) && {
                    
                }} */}

                    {refundAmount &&
                        <div className="row pl-2 pr-2 mt-4">
                            <div className="col-12">
                                <div className="h4-label">Refund Amount: ${refundAmount}</div>
                            </div>
                        </div>
                    }
                    {getUserRole() === "super-admin" && customerDetails?.amount_refunded_flag !== false &&
                        <div className="row pl-2 pr-2 mt-4">
                            <div className="col-12">
                                <div className="h4-label">Refund Amount: ${refundValue?.amount_refund}</div>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </>
    );
}
export default ProductInfo;
