import React, { useState, useEffect } from "react";
import "./MasterData.scss";
import { useHistory } from "react-router-dom";
import MasterDataDB from "../../../assets/images/Masterdata_Main.svg";
import RoleDB from "../../../assets/images/Role_R.svg";
import TerritoryDB from "../../../assets/images/Territory_T.svg";
import CanadaDB from "../../../assets/images/canada.png";
import USADB from "../../../assets/images/usa.png";
import urls from "../../../utils/services/apiUrl.json";
import { getMethod } from "../../../utils/services/api";
import PrivacyDb from "../../../assets/images/Privacy.svg";
import Termsdb from "../../../assets/images/Terms.svg";
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';

const MasterData = (props) => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [roleDetails, setRoleDetails] = useState("");

    const notification = async () => {
        history.push("/notifications");
    };

    const role = async () => {
        history.push("/masterdata/role");
    };

    const territory = async () => {
        history.push("/masterdata/territories");
    };

    const privacy = async () => {
        history.push("/masterdata/privacy_policies");
    };

    const terms = async () => {
        history.push("/masterdata/terms");
    };


    const merchantPrivacy = ()=>{
        history.push("/masterdata/merchant_privacy_policies")
    }

    const merchantTerms = ()=>{
        history.push("/masterdata/merchant_terms")
    }

    const zapperPrivacy = ()=>{
        history.push("/masterdata/zapper_privacy_policies")
    }

    const zapperTerms = ()=>{
        history.push("/masterdata/zapper_terms")
    }

    useEffect(() => {
        getRoleDetails();
    }, []);

    const getRoleDetails = async () => {
        setLoader(true);
        await getMethod(urls.getManagerData).then((response) => {
            if (response.status === 200) {
                window.$roleDetails = response.data;
                setRoleDetails(response.data);
                setLoader(false);
            }
        }).catch((error) => {
            console.log(error);
        });
        setLoader(false);
    };

    return (
        <div className={props.menu ? 'w-100 screenLeftMargin' : 'screenLeftMarginResponse w-100'}>
            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-7">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                        <img width="20" className='mr-2' src={MasterDataDB} />
                        <span className="heading-title">Master Data</span>
                    </div>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <div className="order-notifications-icon" onClick={() => notification()}>
                        <NotificationsIcon style={{ color: "#fff", fontSize: "30px" }} />
                    </div>
                    <div className="order-notifications-icon ml-3">
                        <EmailIcon style={{ color: "#fff", fontSize: "30px" }} />
                    </div>
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div className="roleBox" onClick={() => role()}>
                                <div className="enquiry-text ml-5 mr-5 row">
                                    <div className="col-12 mt-5" style={{ display: "flex", alignItems: "center" }}>
                                        <img alt={""} className="mr-2" src={RoleDB} />
                                        <div> Role</div>
                                    </div>

                                    <div className="role-text col-12 mt-5">
                                        Account Managers with their respective allocated stores details
                                    </div>

                                    <div className="col-12 mt-5" style={{ display: "flex" }}>
                                        {roleDetails && roleDetails.managerImages.length > 0 && roleDetails.managerImages.map((item, index) => {
                                            return (
                                                <div className={`d-flex image-stack-${index}`}>
                                                    <div>
                                                        <img className="roleMan1" src={item.profile_pic.file} alt="" />
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        <span className="roleman-text" style={{ marginTop: "10px" }}>
                                            +{roleDetails.managerCount} Account Managers
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div>
                                <div className="roleBox " onClick={() => territory()}>
                                    <div className="enquiry-text ml-5 mr-5 row">
                                        <div className="col-12 mt-5" style={{ display: "flex", alignItems: "center" }}>
                                            <img className="mr-2" src={TerritoryDB} alt="" />
                                            <div> Territories</div>
                                        </div>

                                        <div className="role-text col-12 mt-5">
                                            Operational Areas where we have expanded our sevices in.
                                        </div>
                                        <div className="col-12 mt-5" style={{ display: "flex" }}>
                                            {roleDetails && roleDetails.countryImages.length > 0 && roleDetails.countryImages.map((item, index) => {
                                                return (
                                                    <div className={`d-flex image-stack-${index}`}>
                                                        <div>
                                                            <img className="roleMan1" src={item.image.file} alt="" />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <span className="roleman-text1 pr-5" style={{ marginTop: "10px" }}>
                                                +{roleDetails.countryCount} Operational Areas
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div>
                                <div className="roleBox" onClick={() => privacy()}>
                                    <div className="enquiry-text ml-5 mr-5 row">
                                        <div className="col-12 mt-5" style={{ display: "flex", alignItems: "center" }}>
                                            <img className="mr-2" src={PrivacyDb} alt="" />
                                            <div> Privacy Policies</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div>
                                <div className="roleBox " onClick={() => terms()}>
                                    <div className="enquiry-text ml-5 mr-5 row">
                                        <div className="col-12 mt-5" style={{ display: "flex", alignItems: "center" }}>
                                            <img className="mr-2" src={Termsdb} alt="" />
                                            <div>Terms Of Use</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div>
                                <div className="roleBox" onClick={() => merchantPrivacy()}>
                                    <div className="enquiry-text ml-5 mr-5 row">
                                        <div className="col-12 mt-5" style={{ display: "flex", alignItems: "center" }}>
                                            <img className="mr-2" src={PrivacyDb} alt="" />
                                            <div>Merchant Privacy Policies</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div>
                                <div className="roleBox " onClick={() => merchantTerms()}>
                                    <div className="enquiry-text ml-5 mr-5 row">
                                        <div className="col-12 mt-5" style={{ display: "flex", alignItems: "center" }}>
                                            <img className="mr-2" src={Termsdb} alt="" />
                                            <div>Merchant Terms Of Use</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5 mt-3">
                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div>
                                <div className="roleBox" onClick={() => zapperPrivacy()}>
                                    <div className="enquiry-text ml-5 mr-5 row">
                                        <div className="col-12 mt-5" style={{ display: "flex", alignItems: "center" }}>
                                            <img className="mr-2" src={PrivacyDb} alt="" />
                                            <div>Zapper Privacy Policies</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div>
                                <div className="roleBox " onClick={() => zapperTerms()}>
                                    <div className="enquiry-text ml-5 mr-5 row">
                                        <div className="col-12 mt-5" style={{ display: "flex", alignItems: "center" }}>
                                            <img className="mr-2" src={Termsdb} alt="" />
                                            <div>Zapper Terms Of Use</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default MasterData;
