import React, { useState } from 'react';
import { toast } from "react-toastify";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import urls from "../../../../utils/services/apiUrl.json";
import Button from "../../../../components/button/button";
import { getMethod, postMethod, putMethod } from "../../../../utils/services/api";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import format from 'date-fns/format';

const ExportData = (props) => {
    const history = useHistory();
    const [salesModal, setSalesModal] = useState(false)
    const [shippingModal, setShippingModal] = useState(false)
    const [exportStartDate, setExportStartDate] = useState("")
    const [exportEndDate, setExportEndDate] = useState("")
    const [shiipingStartDate, setShippingStartDate] = useState("")
    const [shippingEndDate, setShippingEndDate] = useState("")
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const gotoBack = async () => {
        history.push('/masterdata')
    };

    const allStyless = {
        content: {
            top: "31%",
            left: "30%",
            transform: screenSize.width <= 1100 ? 'translate(-60%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 1100 ? "35vw" : "25vw",
            height: "23vh",
        },
    };

    const handleOpenSalesModal = () => {
        setSalesModal(true)
    }

    const handleCloseSalesModal = () => {
        setSalesModal(false)
    }

    const handleChangeDate = (date) => {
        setExportStartDate(date);
    }

    const handleEndChangeDate = (date) => {
        setExportEndDate(date)
    }

    const handleExportData = async () => {
        try {
            const response = await getMethod(urls.exportData + "?start_date=" + format(exportStartDate, 'yyyy-MM-dd') + "&end_date=" + format(exportEndDate, 'yyyy-MM-dd'));

            if (response.status === 200 || response.status === 201) {
                const data = response.data?.response;
                setSalesModal(false)

                if (!Array.isArray(data) || data.length === 0) {
                    console.error("No data available to export");
                    return;
                }

                const headers = Object.keys(data[0]).join(",");

                const rows = data.map(row =>
                    Object.keys(row)
                        .map(key => row[key])
                        .join(",")
                ).join("\n");

                const csvContent = headers + "\n" + rows;
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;
                link.download = "export sells report.csv";
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                toast.success('CSV file exported and downloaded successfully')
            }
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    }

    const handleOpenShippingModal = () => {
        setShippingModal(true)
    }

    const handleCloseShippingModal = () => {
        setShippingModal(false)
    }

    const handleStartShipChangeDate = (date) => {
        setShippingStartDate(date);
    }

    const handleEndShipChangeDate = (date) => {
        setShippingEndDate(date)
    }

    const handleShipData = async() =>{
        try {
            const response = await getMethod(urls.shippingData + "?start_date=" + format(shiipingStartDate, 'yyyy-MM-dd') + "&end_date=" + format(shippingEndDate, 'yyyy-MM-dd'));

            if (response.status === 200 || response.status === 201) {
                const data = response.data?.response;
                setShippingModal(false)

                if (!Array.isArray(data) || data.length === 0) {
                    console.error("No data available to export");
                    return;
                }

                const headers = Object.keys(data[0]).join(",");

                const rows = data.map(row =>
                    Object.keys(row)
                        .map(key => row[key])
                        .join(",")
                ).join("\n");

                const csvContent = headers + "\n" + rows;
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;
                link.download = "export ship data report.csv";
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                toast.success('CSV file exported and downloaded successfully')
            }
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    }

    return (
        <>
            <div className={props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
                <div className="row ml-3 pl-3 mr-3 mt-3 mobileResponsive">
                    <div onClick={() => gotoBack()} style={{ cursor: "pointer" }}>
                        <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Master Data</a>
                    </div>
                </div>
                <div className="d-flex mt-4">
                    <div className="ml-4 pl-4 mr-3">
                        <Button onClick={() => handleOpenSalesModal()} className="btn btn-cancle btn-lg" name="Export Sales Data"></Button>
                    </div>
                    <div className="mr-3">
                        <Button onClick={() => handleOpenShippingModal()} className="btn btn-cancle btn-lg" name="Export Shipping Data"></Button>
                    </div>
                </div>
            </div>

            <Modal isOpen={salesModal} style={allStyless} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="d-flex justify-content-between">
                    <div className="filter-text">Export Sales Data</div>
                    <div>
                        <button onClick={() => handleCloseSalesModal()} className="filterCloseIcon">
                            <CloseIcon style={{ fontSize: "18px" }} />
                        </button>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="Start Date"
                                className={exportStartDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={exportStartDate ? format(exportStartDate, 'yyyy MM dd') : ''}
                                onChange={(e) => handleChangeDate(e)}
                                error={false}
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="End Date"
                                className={exportEndDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={exportEndDate ? format(exportEndDate, 'yyyy MM dd') : ''}
                                onChange={(e) => handleEndChangeDate(e)}
                                error={false}
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                        <Button className="btn btn-primary" name="Export" onClick={() => handleExportData()}></Button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={shippingModal} style={allStyless} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="d-flex justify-content-between">
                    <div className="filter-text">Export Shipping Data</div>
                    <div>
                        <button onClick={() => handleCloseShippingModal()} className="filterCloseIcon">
                            <CloseIcon style={{ fontSize: "18px" }} />
                        </button>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="Start Date"
                                className={ shiipingStartDate!== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={shiipingStartDate ? format(shiipingStartDate, 'yyyy MM dd') : ''}
                                onChange={(e) => handleStartShipChangeDate(e)}
                                error={false}
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="End Date"
                                className={shippingEndDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={shippingEndDate ? format(shippingEndDate, 'yyyy MM dd') : ''}
                                onChange={(e) => handleEndShipChangeDate(e)}
                                error={false}
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                        <Button className="btn btn-primary" name="Export" onClick={() => handleShipData()}></Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ExportData
