import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PrivacyDb from "../../../../assets/images/Privacy.svg";
import JoditEditor from "jodit-react";
import urls from "../../../../utils/services/apiUrl.json";
import Button from "../../../../components/button/button";
import { getMethod, postMethod, putMethod } from "../../../../utils/services/api";
import { toast } from "react-toastify";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';

const ZapperPolicy = (props) => {
    const [zapperPolicy, setZapperPolicy] = useState("");
    const [state, setState] = useState("");
    const editor = useRef(null);
    const history = useHistory();
    const config = {
        readonly: false,
        placeholder: "PrivacyPolicies",
    };

    const gotoBack = async () => {
        history.push('/masterdata')
    };

    const notification = async () => {
        history.push("/notifications");
    };

    useEffect(() => {
        getZapperPolicyData();
    }, [])

    const getZapperPolicyData = async () => {
        await getMethod(urls.zapperPolicy).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setZapperPolicy(response?.data)
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const postZapperPolicy = async (method, id) => {
        if (state.terms !== "") {
            let body = {
                privacy_and_policy_text: state,
            };

            if (method === "POST") {
                await postMethod(urls.zapperPolicy, body).then((response) => {
                    if (response.status === 200 || response.status === 201 || response.status === 202) {
                        toast.success(response?.data?.response);
                    } else {
                        toast.error(response?.data?.response);
                    }
                });
            } else {
                await putMethod(
                    urls.zapperPolicy + zapperPolicy?.data[0].id + "/",
                    body
                ).then((response) => {
                    if (response.status === 200 || response.status === 201 || response.status === 202) {
                        toast.success(response?.data?.response);
                    } else {
                        toast.error(response?.data?.response);
                    }
                });
            }
        }
    };

    return (
        <div className={props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
            <div className="row ml-3 pl-3 mr-3 mt-3 mobileResponsive">
                <div onClick={() => gotoBack()} style={{ cursor: "pointer" }}>
                    <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Master Data</a>
                </div>
            </div>
            <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                <div className="col-7 d-flex">
                    <div className="d-flex mr-3">
                        <img width="25" src={PrivacyDb} />
                    </div>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                        <h1 className="heading-title d-flex">Zapper Privacy Policies</h1>
                    </div>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <div className="order-notifications-icon" onClick={() => notification()}>
                        <NotificationsIcon style={{ color: "#fff", fontSize: "30px" }} />
                    </div>
                    <div className="order-notifications-icon ml-3">
                        <EmailIcon style={{ color: "#fff", fontSize: "30px" }} />
                    </div>
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-12">
                    <JoditEditor
                        ref={editor}
                        value={zapperPolicy !== "" && zapperPolicy?.data?.[0] !== "undefined" ? zapperPolicy?.data[0]?.privacy_and_policy_text : ""}
                        config={config}
                        onBlur={(newContent) => setState(newContent)}
                        onChange={(newContent) => {
                            console.log("entered input", newContent);
                        }}
                    />
                </div>

                <div className="col-12 mt-3">
                    <div className="d-flex justify-content-end">
                        <Button
                            onClick={() => zapperPolicy?.data && zapperPolicy?.data.length === 0 ? postZapperPolicy("POST") : postZapperPolicy("UPDATE")}
                            className="btn btn-primary"
                            name="Save"
                        >
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ZapperPolicy
