import React, { useEffect, useState } from "react";
import "./Invoice.scss";
import FaceBook from "../../../../assets/images/Facebook_F.svg";
import Twitter from "../../../../assets/images/Twitter_T.svg";
import Insta from "../../../../assets/images/Instagram_I.svg";
import { getMethodWithOrderListPlatfrom, getMethodWithPlatfrom, getMethodWithStripe } from "../../../../utils/services/api";
import { getUserRole } from "../../../../utils/common";
import urls from "../../../../utils/services/apiUrl.json";
import moment from "moment";
import Barcode from "react-barcode/lib/react-barcode";
import Box from "@material-ui/core/Box";
import DefaultImage from "../../../../assets/images/ShopezDefult.svg"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


function InvoiceSlip({ orderId, storeId, customerDetails, orderStatus }) {
    const OrderIdParam = useParams()
    const [loading, setLoading] = useState(false);
    const [orderInvoiceSlip, setOrderInvoiceSlip] = useState({});
    const [creditCardNumber, setCreditCardNumber] = useState("");

    const getInvoiceDetails = async (url, type) => {
        setLoading(true);
        await getMethodWithOrderListPlatfrom(url, type).then((response) => {
            if (response.status === 200) {
                setOrderInvoiceSlip(response.data);
                setLoading(false);

                if (response.data.customerCardId && response.data.customerStripeID) {
                    getMethodWithStripe(urls.postStripeCustomers + '/' + response.data.customerStripeID + '/sources/' + response.data.customerCardId).then((result) => {
                        setCreditCardNumber(` (**** **** **** ${result.data && result.data.last4})`);
                    }).catch((error) => {
                        console.log("error", error);
                    });
                }

            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (getUserRole() !== "super-admin") {
            getInvoiceDetails(urls.ordersMerchant + "?orders_id_invoice=" + orderId, "merchant");
        } else {
            getInvoiceDetails(
                urls.ordersAdmin + "?stores_id=" + storeId + "&orders_id_invoice=" + orderId + (OrderIdParam?.express === "expressDelivery" ? "&for_express=1" : OrderIdParam?.order === "pickUp" ? "&for_pick_up=1" : "&for_delivery=1"), "super-admin");
        }
    }, []);

    const getDateFormat = (date) => {
        if (date !== null && date !== "") {
            return moment(date).format("L");
        }
        return;
    };
    const timeFormat = (date) => {
        let requiredFormat = moment(date).format("lll");
        return requiredFormat;
    };

    return (
        <div className="row" style={{ marginLeft: "32px" }}>
            <div className="col-12 p-0" style={{ overflowX: "hidden" }}>
                <div className="d-flex p-0 mt-4">
                    {OrderIdParam.order === "pickUp" ?
                        <div className="h4-label" style={{ display: "flex", alignItems: "center", paddingLeft: "14px" }}>
                            Pick Up Order {customerDetails && customerDetails?.number}
                        </div>
                        :
                        <div className="h4-label" style={{ display: "flex", alignItems: "center", paddingLeft: "12px" }}>
                            Ship to Address Order {customerDetails && customerDetails?.number}
                        </div>
                    }
                    <Box style={{
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        maxWidth: '158px',
                        height: "32px",
                        marginLeft: '20px',
                        display: 'flex',
                        alignItems: "center"
                    }}
                        bgcolor="#00B050" color="white" textAlign="center" borderRadius="20px">
                        {customerDetails?.paymentConfirmation}
                    </Box>
                    {(OrderIdParam?.express && (orderStatus === "Order Received" || orderStatus === "Order in Process" || orderStatus === "Order Ready")) &&
                        <Box style={{ paddingLeft: '15px', paddingRight: '15px', maxWidth: '148px', height: "32px", marginLeft: '15px', display: 'flex', alignItems: "center" }} bgcolor="#FF0000" color="white" textAlign="center" borderRadius="20px">
                            Rush Order
                        </Box>
                    }
                    {(OrderIdParam?.express && orderStatus === "Order Delivered") &&
                        <Box style={{ paddingLeft: '15px', paddingRight: '15px', maxWidth: '148px', height: "32px", marginLeft: '15px', display: 'flex', alignItems: "center" }} bgcolor="#1D3762" color="white" textAlign="center" borderRadius="20px">
                            Order Delivered
                        </Box>
                    }

                </div>
                <div className="col-md-12 col-lg-12">
                    <div className="shiptext2 mt-3">
                        Received on{" "}
                        {customerDetails && customerDetails?.date_placed ? timeFormat(customerDetails.date_placed) : ""}
                        {" "}
                        from{" "}
                        {customerDetails && customerDetails?.first_name + " " + customerDetails?.last_name}
                    </div>
                </div>

                <div className="row mt-4 mr-4">
                    <div className="col-6">
                        <div className="h5-label">Invoice</div>
                        <div className="muiDescripationInfo">
                            <span><b>Order:</b> </span>
                            {orderInvoiceSlip?.number}
                        </div>

                        <div className="muiDescripationInfo">
                            <span><b>Order Date:</b> </span>
                            {getDateFormat(orderInvoiceSlip?.date_placed)}
                        </div>

                        <div className="muiDescripationInfo">
                            <span><b>Shipping:</b> </span>
                            {OrderIdParam?.express === "expressDelivery" ? "Express Shipping" : OrderIdParam.order === "pickUp" ? "In-Store Pickup" : "Standard Delivery"}
                        </div>

                        <div className="muiDescripationInfo">
                            <span><b>Payment:</b> </span>
                            {creditCardNumber !== "" && creditCardNumber}
                            {/* {orderInvoiceSlip && orderInvoiceSlip.payment_info && orderInvoiceSlip.payment_info.brand}({orderInvoiceSlip && orderInvoiceSlip.payment_info && orderInvoiceSlip.payment_info.card}) */}
                        </div>
                    </div>

                    <div className="col-6" style={{ textAlign: "right" }}>
                        <img className="listedImg100100" src={orderInvoiceSlip?.merchant_data?.store_logo?.[0]?.file ? orderInvoiceSlip?.merchant_data?.store_logo?.[0]?.file : DefaultImage} />
                        {/*<Barcode value={orderInvoiceSlip && orderInvoiceSlip.number} />*/}
                    </div>
                </div>

                <div className="row mt-4 mr-4">
                    <div className="col-6 billto">
                        <div className="h5-label">Bill to</div>
                        <div className="muiDescripationInfoCheckbox">
                            {orderInvoiceSlip && orderInvoiceSlip.billing_address && orderInvoiceSlip.billing_address.line1 && orderInvoiceSlip.billing_address.line1}
                            {orderInvoiceSlip && orderInvoiceSlip.billing_address && orderInvoiceSlip.billing_address.line2 && ", " + orderInvoiceSlip.billing_address.line2}
                            {orderInvoiceSlip && orderInvoiceSlip.billing_address && orderInvoiceSlip.billing_address.line3 && ", " + orderInvoiceSlip.billing_address.line3}
                            {orderInvoiceSlip && orderInvoiceSlip.billing_address && orderInvoiceSlip.billing_address.line4 && ", " + orderInvoiceSlip.billing_address.line4}
                            {orderInvoiceSlip && orderInvoiceSlip.billing_address && orderInvoiceSlip.billing_address.state && ", " + orderInvoiceSlip.billing_address.state}
                            {orderInvoiceSlip && orderInvoiceSlip.billing_address && orderInvoiceSlip.billing_address.postcode && ", " + orderInvoiceSlip.billing_address.postcode}
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="h5-label">Ship to</div>
                        <div className="muiDescripationInfoCheckbox">
                            {orderInvoiceSlip && orderInvoiceSlip.shipping_address && orderInvoiceSlip.shipping_address.line1 && orderInvoiceSlip.shipping_address.line1}
                            {orderInvoiceSlip && orderInvoiceSlip.shipping_address && orderInvoiceSlip.shipping_address.line2 && ", " + orderInvoiceSlip.shipping_address.line2}
                            {orderInvoiceSlip && orderInvoiceSlip.shipping_address && orderInvoiceSlip.shipping_address.line3 && ", " + orderInvoiceSlip.shipping_address.line3}
                            {orderInvoiceSlip && orderInvoiceSlip.shipping_address && orderInvoiceSlip.shipping_address.line4 && ", " + orderInvoiceSlip.shipping_address.line4}
                            {orderInvoiceSlip && orderInvoiceSlip.shipping_address && orderInvoiceSlip.shipping_address.state && ", " + orderInvoiceSlip.shipping_address.state}
                            {orderInvoiceSlip && orderInvoiceSlip.shipping_address && orderInvoiceSlip.shipping_address.postcode && ", " + orderInvoiceSlip.shipping_address.postcode}
                        </div>
                        <div className="muiDescripationInfoCheckbox">
                            {orderInvoiceSlip && orderInvoiceSlip.shipping_address && orderInvoiceSlip.shipping_address.phone_number}
                        </div>
                    </div>
                </div>

                <div className="row mt-3 mr-2">
                    <div className="col-12">
                        <hr style={{ border: "1px solid #C0C0C0", marginTop: "4vw" }} />
                    </div>
                </div>

                <div className="row mr-2">
                    <div className="col-6 f-600">Item Discription</div>
                    <div className="col-2 f-600">Qty</div>
                    <div className="col-2 f-600">Price</div>
                    <div className="col-2 f-600">Total</div>
                </div>

                <div className="row mt-3 mr-2">
                    <div className="col-12">
                        <hr style={{ border: "1px solid #C0C0C0" }} />
                    </div>
                </div>

                {orderInvoiceSlip && orderInvoiceSlip.ordered_product !== undefined && orderInvoiceSlip.ordered_product.length > 0 ? orderInvoiceSlip.ordered_product.map((item, index) => {
                    return (
                        <div className="row mr-2 pb-3">
                            <div className="col-6 d-flex">
                                <div className="d-flex">
                                    <img className="listedImg" src={item.product_image[0].original} alt={item.product_name} />
                                </div>
                                <div className="ml-2" style={{ alignItems: "center" }}>
                                    <div className="h6-label">{item.product_name}</div>
                                    <div className="pDetail"><b style={{ textTransform: "capitalize" }}>sku</b> : {item.partner_sku}</div>
                                    {item.attributes.length > 0 &&
                                        (
                                            <>
                                                {item.attributes.map((attribute, index) => {
                                                    return (
                                                        <>
                                                            {attribute.value !== null && attribute.code !== "color_code_swatch" &&
                                                                <div className="pDetail" style={{ textTransform: "capitalize" }}><b>{attribute.name}</b> : {attribute.value}</div>
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                {item?.ordered_quantity}
                            </div>
                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                $ {item?.total_excl_tax}
                            </div>
                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                $ {item?.total_excl_tax}
                            </div>
                        </div>
                    );
                }) : ""}

                <div className="row mr-2">
                    <div className="col-12">
                        <hr style={{ border: "1px solid #C0C0C0" }} />
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-7">
                        {/*<p>
                            <span style={{ color: "orange", paddingLeft: "1.3vw" }}>
                                Note:
                            </span>{" "}
                            This is a gift.
                        </p>*/}
                    </div>
                    {orderInvoiceSlip?.discount &&
                        <>
                            <div className="col-9 d-flex justify-content-end">
                                <p className="f-600 mr-5">Discount</p>
                            </div>
                            <div className="col-3 d-flex justify-content-center">
                                <p className="ml-2">${orderInvoiceSlip?.discount}</p>
                            </div>
                        </>
                    }
                </div>

                {orderInvoiceSlip?.discount &&
                    <div className="row ml-2 mr-2">
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                            <hr style={{ border: "1px solid rgba(83, 83, 83, 0.1)" }} />
                        </div>
                    </div>
                }

                <div className="row ml-2 mr-2">
                    <div className="col-9 d-flex justify-content-end">
                        <div className="f-600 mr-5 ">SubTotal</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2">${orderInvoiceSlip?.orderSummary?.[0]?.subTotal}</p>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <hr style={{ border: "1px solid rgba(83, 83, 83, 0.1)" }} />
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-9 d-flex justify-content-end">
                        <div className="f-600 mr-5 ">Shipping</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2">${orderInvoiceSlip?.orderSummary?.[0]?.shippingCharge}</p>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <hr style={{ border: "1px solid rgba(83, 83, 83, 0.1)" }} />
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-9 d-flex justify-content-end">
                        <div className="f-600 mr-5 ">Sales Tax</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2">${orderInvoiceSlip?.orderSummary?.[0]?.totalTax}</p>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <hr style={{ border: "1px solid #535353" }} />
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-9 d-flex justify-content-end">
                        <div className="f-600 mr-5">Total</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2">${orderInvoiceSlip?.orderSummary?.[1]?.orderTotal}</p>
                    </div>
                </div>

                <div className="row mt-2 mb-5 ml-2 mr-2">
                    <div className="col-12">
                        <div className="h5-label text-center">Thank for your business!</div>
                        {/* <div className="muiDescripationInfo text-center">
                            Please complete the above form & return it with items carefully packed.
                        </div> */}

                        <div className="mt-2">
                            <div className="h5-label text-center">
                                {orderInvoiceSlip && orderInvoiceSlip.merchant_data && orderInvoiceSlip && orderInvoiceSlip.merchant_data.store_name}
                            </div>
                            <div className="muiDescripationInfo text-center">
                                {orderInvoiceSlip && orderInvoiceSlip.merchant_data && orderInvoiceSlip.merchant_data.address}
                                {orderInvoiceSlip && orderInvoiceSlip.merchant_data && ", " + orderInvoiceSlip.merchant_data.city}
                                {orderInvoiceSlip && orderInvoiceSlip.merchant_data && ", " + orderInvoiceSlip.merchant_data.state}
                                {orderInvoiceSlip && orderInvoiceSlip.merchant_data && ", " + orderInvoiceSlip.merchant_data.country}
                                {orderInvoiceSlip && orderInvoiceSlip.merchant_data && ", " + orderInvoiceSlip.merchant_data.postal_code}
                            </div>
                            <div className="muiDescripationInfo text-center">
                                Tel {orderInvoiceSlip && orderInvoiceSlip.merchant_data && orderInvoiceSlip.merchant_data.contact_number}
                            </div>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img style={{ marginRight: "1vw" }} src={FaceBook} />
                            <img style={{ marginRight: "1vw" }} src={Twitter} />
                            <img style={{ marginRight: "1vw" }} src={Insta} />
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <Barcode value={orderInvoiceSlip && orderInvoiceSlip.number} />
                        </div>
                        <div className="muiDescripationInfo text-center">
                            Please complete the above form & return it with items carefully packed.
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
export default InvoiceSlip;
